<template>
  <form-view v-loading="loading" type="verify" :verify-method="verifyMethod">
    <div class="section-title-row">
      <div class="title">车辆基本信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="自编号" prop="number">
          <el-input disabled v-model="dataForm.number"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车牌号码" prop="licensePlate">
          <el-input disabled v-model="dataForm.licensePlate"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="终端号" prop="simCardNumber">
          <el-input disabled v-model="dataForm.simCardNumber"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="车辆类别" prop="carSize">
          <el-select disabled filterable v-model="dataForm.carSize">
            <el-option v-for="s in sizeList" :key="s" :label="s" :value="s"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="出厂编号" prop="factoryNumber">
          <el-input disabled v-model="dataForm.factoryNumber"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="启用日期" prop="startupDate">
          <el-date-picker disabled value-format="timestamp" v-model="dataForm.startupDate"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="所属公司" prop="companyId">
          <el-select disabled filterable v-model="dataForm.companyId">
            <el-option v-for="s in companyList" :key="s.id" :label="s.companyName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="排放标准" prop="emissionStandard">
          <el-input disabled v-model="dataForm.emissionStandard"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车辆类型" prop="carGasType">
          <el-select disabled filterable v-model="dataForm.carGasType">
            <el-option
              v-for="s in carGasType"
              :key="s.dictId"
              :label="s.dictName"
              :value="s.dictId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25" v-if="isOwned">
      <el-col :span="8">
        <el-form-item label="所属基地" prop="baseId">
          <el-select disabled filterable v-model="dataForm.baseId">
            <el-option v-for="s in baseList" :key="s.id" :label="s.name" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="发动机号" prop="engineNumber">
          <el-input disabled v-model="dataForm.engineNumber"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车架号" label-width="82px" prop="identificationNumber">
          <el-input disabled v-model="dataForm.identificationNumber"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="底盘" prop="chassis">
          <el-input disabled v-model="dataForm.chassis"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="底盘品牌" prop="chassisBrand">
          <el-input disabled v-model="dataForm.chassisBrand"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="102px" label="底盘轴数" prop="chassisNumber">
          <el-input-number disabled v-model="dataForm.chassisNumber" :controls="false"></el-input-number>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="负责人" prop="principalName">
          <el-select disabled filterable v-model="dataForm.headUserId">
            <el-option v-for="s in principalList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="泵车品牌" prop="carBrand">
          <el-input disabled v-model="dataForm.carBrand"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="102px" label="车辆购买日期" prop="purchaseDate">
          <el-date-picker disabled value-format="timestamp" v-model="dataForm.purchaseDate"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">臂展详情</div>
      <div class="line"></div>
    </div>
    <div class="bracket-area">
      <el-row>
        <el-col :span="6">
          <el-form-item label-width="110px" label="支腿类型" prop="bracketType">
            <el-select disabled filterable v-model="dataForm.bracketType">
              <el-option
                v-for="s in bracketType"
                :key="s.dictId"
                :label="s.dictName"
                :value="s.dictId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label-width="110px" label="全展前左腿长度" prop="bracketFullBeforeLeft">
            <el-input disabled type="number" v-model="dataForm.bracketFullBeforeLeft"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label-width="110px" label="半展前左腿长度" prop="bracketHalfBeforeLeft">
            <el-input disabled type="number" v-model="dataForm.bracketHalfBeforeLeft"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label-width="110px" label="全展前右腿长度" prop="bracketFullBeforeRight">
            <el-input disabled type="number" v-model="dataForm.bracketFullBeforeRight"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label-width="110px" label="半展前右腿长度" prop="bracketHalfBeforeRight">
            <el-input disabled type="number" v-model="dataForm.bracketHalfBeforeRight"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label-width="110px" label="全展后左腿长度" prop="bracketFullAfterLeft">
            <el-input disabled type="number" v-model="dataForm.bracketFullAfterLeft"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label-width="110px" label="半展后左腿长度" prop="bracketHalfAfterLeft">
            <el-input disabled type="number" v-model="dataForm.bracketHalfAfterLeft"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label-width="110px" label="全展后右腿长度" prop="bracketFullAfterRight">
            <el-input disabled type="number" v-model="dataForm.bracketFullAfterRight"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label-width="110px" label="半展后右腿长度" prop="bracketHalfAfterRight">
            <el-input disabled type="number" v-model="dataForm.bracketHalfAfterRight"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <div class="section-title-row">
      <div class="title">驾操人员信息</div>
      <div class="line"></div>
    </div>
    <el-row class="flex-row" v-for="(d, i) in dataForm.carDriverList" :key="i">
      <label>岗位</label>
      <el-select disabled v-model="d.post">
        <el-option v-for="s in carPost" :key="s.dictId" :label="s.dictName" :value="s.dictId"></el-option>
      </el-select>
      <label>用户姓名</label>
      <el-select disabled v-model="d.userId">
        <el-option v-for="s in userList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
      </el-select>
      <label></label>
      <el-select disabled v-model="d.functionName">
        <el-option v-for="s in carDriver" :key="s.dictId" :label="s.dictName" :value="s.dictId"></el-option>
      </el-select>
      <label>备注</label>
      <el-input disabled class="remark" v-model="d.remarks"></el-input>
    </el-row>
    <div class="section-title-row">
      <div class="title">保险记录</div>
      <div class="line"></div>
    </div>
    <div class="insurance-area" v-for="(r, i) in dataForm.carInsuranceList" :key="'ins_' + i">
      <el-row class="flex-row">
        <label>开始时间</label>
        <el-date-picker disabled value-format="timestamp" v-model="r.startDate"></el-date-picker>
        <label>结束时间</label>
        <el-date-picker disabled value-format="timestamp" v-model="r.endDate"></el-date-picker>
        <label>保险类型</label>
        <el-select disabled v-model="r.insuranceType">
          <el-option
            v-for="s in carInsurance"
            :key="s.dictId"
            :label="s.dictName"
            :value="s.dictId"
          ></el-option>
        </el-select>
        <label>保险公司</label>
        <el-input disabled v-model="r.insuranceCompany"></el-input>
      </el-row>
      <image-uploader disabled :images="r.images || []" no-title></image-uploader>
    </div>
    <div class="section-title-row">
      <div class="title">行驶证信息</div>
      <div class="line"></div>
    </div>
    <el-row>
      <el-col :span="8">
        <el-form-item label-width="120px" label="行驶证到期日期" prop="drivingLicenseExpireDate">
          <el-date-picker
            disabled
            value-format="timestamp"
            v-model="dataForm.drivingLicenseExpireDate"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <div class="license-area">
          <div class="upload-wrapper">
            <image-uploader disabled :limit="1" :images="beforeImageList || []" no-title></image-uploader>
            <div class="title">行驶证正面</div>
          </div>
          <div class="upload-wrapper">
            <image-uploader disabled :limit="1" :images="afterImageList || []" no-title></image-uploader>
            <div class="title">行驶证反面</div>
          </div>
          <div class="upload-wrapper">
            <image-uploader disabled :limit="1" :images="subCardImageList || []" no-title></image-uploader>
            <div class="title">行驶证副卡</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">操作历史</div>
      <div class="line"></div>
    </div>
    <div class="record-area">
      <div class="record-row" v-for="(r, i) in records" :key="i">{{r}}</div>
    </div>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
import dayjs from 'dayjs'
import { operationTypes } from '@/utils/constants'

export default {
  name: 'car-car-verify',

  components: { FormView, ImageUploader },

  data () {
    return {
      loading: false,
      dataForm: {},
      sizeList: [],
      carGasType: [],
      companyList: [],
      baseList: [],
      bracketType: [],
      carInsurance: [],
      carPost: [],
      carDriver: [],
      userList: [],
      beforeImageList: [],
      afterImageList: [],
      subCardImageList: [],
      records: []
    }
  },

  computed: {
    isOwned () {
      return this.companyList.length &&
        this.dataForm.companyId &&
        this.companyList.find(c => c.id === this.dataForm.companyId).companyType === 1
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.$route.query.id
      if (id) {
        this.loading = true
        const data = await this.$http({
          url: this.$http.adornUrl('/car/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          this.dataForm.purchaseDate = dayjs(this.dataForm.purchaseDate).valueOf()
          this.dataForm.startupDate = dayjs(this.dataForm.startupDate).valueOf()
          this.dataForm.drivingLicenseExpireDate = dayjs(this.dataForm.drivingLicenseExpireDate).valueOf()
          this.dataForm.carInsuranceList.forEach(i => {
            i.startDate = dayjs(i.startDate).valueOf()
            i.endDate = dayjs(i.endDate).valueOf()
            i.insuranceType = parseInt(i.insuranceType)
            if (i.insuranceImage) {
              i.images = i.insuranceImage.split(',').map(j => ({ url: '/files' + j }))
            } else {
              i.images = []
            }
          })
          this.dataForm.bracketType = parseInt(this.dataForm.bracketType)
          this.dataForm.carGasType = parseInt(this.dataForm.carGasType)
          this.dataForm.drivingLicenseBeforeImage && (this.beforeImageList = [{ url: '/files' + this.dataForm.drivingLicenseBeforeImage }])
          this.dataForm.drivingLicenseAfterImage && (this.afterImageList = [{ url: '/files' + this.dataForm.drivingLicenseAfterImage }])
          this.dataForm.drivingLicenseSubCardImage && (this.subCardImageList = [{ url: '/files' + this.dataForm.drivingLicenseSubCardImage }])
          const res = await this.$http({
            url: this.$http.adornUrl('/car/operationRecord'),
            method: 'post',
            data: id
          })
          this.records = res.datas.map(d => {
            return `${d.createTime} ${d.nickName}进行了${operationTypes[d.typeCode]}操作。`
          })
          this.loading = false
        }
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/car/otherData'),
        method: 'post'
      })
      for (const key in data.datas) {
        if (data.datas.hasOwnProperty(key)) {
          const v = data.datas[key]
          this[key] = v
        }
      }
      data = await this.$http({
        url: this.$http.adornUrl('/car/listCarSize'),
        method: 'post'
      })
      this.sizeList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/user/list'),
        method: 'post',
        data: {}
      })
      this.userList = data.datas
    },

    verifyMethod () {
      return this.$http({
        url: this.$http.adornUrl('/car/review'),
        method: 'post',
        data: this.$route.query.id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bracket-area {
  .el-form-item__content {
    white-space: nowrap;
    .el-input {
      width: 80%;
    }
    .el-input + label {
      margin-left: 10px;
    }
  }
}
.flex-row {
  margin: 20px 0;
  display: flex;
  align-items: center;
  .el-select,
  .el-date-editor {
    width: 150px;
    flex: 0 0 auto;
  }
  label {
    margin: 0 10px;
    white-space: nowrap;
  }
  .el-input.remark {
    flex: 1;
    width: auto;
  }
  .icon {
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
    color: #666;
    &:hover {
      color: #999;
    }
  }
}
.license-area {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>
